import * as React from 'react';
import PageSeo from '../../components/Seo/PageSeo';
import PageTitle from '../../components/PageTitle';
import styled from 'styled-components';
import PageSection from '../../components/PageSection';
import { CalloutBanner, PageSeparator, TextBlock } from '../../components';
import * as colors from '../../styles/shared/colors';
import { respondTo } from '../../styles/shared/breakpoints';
import MechImage from '../../assets/images/mechanism-of-action.webp';
import MechImageMobile from '../../assets/images/mechanism-of-action-mobile.png';
import Image from '../../components/Image';

// SEO INFORMATION
const pageMeta = {
  title: 'Mechanism of Action for WELIREG™ (belzutifan)',
  keywords: 'welireg mechanism of action for advanced rcc, belzutifan mechanism of action for advanced rcc',
  description:  'Health care professionals can review the mechanism of action (MOA) for WELIREG™ (belzutifan).',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/mechanism-of-action/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/mechanism-of-action/","@type":"Drug","proprietaryName":"WELIREG™","nonProprietaryName":"belzutifan","mechanismofAction":"WELIREG is a selective HIF-2α inhibitor, which works in the nucleus and may reduce the transcription of certain target genes in advanced RCC","manufacturer":"Merck","prescribingInfo":"https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_pi.pdf"}`
  ]
}

const StandardSection = styled(PageSection)`
  display: flex;
  justify-content: center;
  padding: 0;
`;

const calloutBanner = {
  title: 'Connect with a Merck Associate',
  externalInvertedButtons: true,
  links: [
    {
      label: 'Ask Merck',
      url: 'https://www.askmerck.com/',
    },
  ],
};

const firstAndOnlyTextBlock = {

  body: <>
    <p>The approval of WELIREG marks the first treatment option in a novel therapeutic class available for your appropriate patients with advanced RCC since 2015<sup>1</sup></p>
  </>,
};
const imageData= {
  footnotes: [
    {
      label: '',
      text: 'HIF-2α = hypoxia-inducible factor 2 alpha; HIF-1β = hypoxia factor 1 beta.',
    },
  ],
  definitions: '',
};

const MechImageCont = styled(Image)`
padding: 8px;
  img {
    width: 100%;
  }
`;

const BlueTextBlock = styled.div`
  background-color: ${colors.tintsBlueLt2};
  border-radius: 8px;
  padding: 12px;
  margin-top: 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;

  p {
    color: ${colors.brandBlueDark};
    margin-bottom: 0;
    max-width: 892px;
  }

  ${respondTo.sm`
    padding: 12px 40px;
  `}

`;

const MOATextBlock = styled(TextBlock)`
  b{
    font-weight: 600;
  }
  i {
    font-style: italic;
  }
`;

const TitleWrapper = styled.div`
  h1{
    word-break: break-word;
  }

  span{
    white-space: nowrap;
  }
`;


const IndexPage = () => {
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/advanced-renal-cell-carcinoma/mechanism-of-action/" />
      </PageSeo>

      <TitleWrapper>
        <PageTitle unbranded>
            Mechanism of Action <span>for WELIREG</span><b>™</b><span> (belzutifan)</span>
        </PageTitle>
      </TitleWrapper>

      <StandardSection>
        <PageSeparator topPadding='28px' />
      </StandardSection>

      <StandardSection>
        <MechImageCont
          maxWidth="100%"
          title="WELIREG is a selective HIF-2α inhibitor, which works in the nucleus and may reduce the transcription of certain target genes in advanced RCC"
          subtext=""
          graphTitle=""
          imgPath={MechImage}
          mobImgPath={MechImageMobile}
          imgAlt="Mechanism of Action for WELIREG™ (belzutifan): WELIREG Binds to HIF-2α, Which Blocks Interaction With HIF-1β and May Reduce Downstream Activities of Cellular Proliferation Angiogenesis, and Tumor Growth"
          {...imageData}
        />
        <BlueTextBlock>
            {firstAndOnlyTextBlock.body}
        </BlueTextBlock>
        <PageSeparator topPadding='16px' topPaddingMobile='16px' />

        <MOATextBlock
          footer={{ definitions: <><strong>Reference: 1.</strong> Yap NY, Khoo WT, Perumal K, et al. Practical updates in medical therapy for advanced and metastatic renal cell carcinoma. <i>Urol Sci</i>. 2018;29(3):120–128.</>}}
        >
        </MOATextBlock>
      </StandardSection>

      <PageSeparator topPadding='48px' topPaddingMobile='32px' />

      <CalloutBanner {...calloutBanner} />

    </>
  );
};

export default IndexPage;
